import { GetCurrentSubscriptionRes, GetUserAndActiveCourseResponse } from 'graphql/queries/user/getUser'
import { subsDistributionParamsFactory } from 'graphql/schemas/company/Company'
import { isCourseExpired } from 'graphql/schemas/course/Course'
import React from 'react'
import intl from 'react-intl-universal'
import { MoreButton } from './MoreButton'
import { Button } from 'components/utils/Button'
import { isAdmin, isAgent } from 'graphql/schemas/user/User'
import Tippy from '@tippyjs/react'
import { trackButtonEnhanced } from 'utils/track'
import { stringSlicer } from 'utils/functions'
import { useNavigate } from 'react-router-dom'

type ActiveCourseProps = {
  menuExpanded: boolean;
  userData: GetUserAndActiveCourseResponse;
  subData: GetCurrentSubscriptionRes;
  showEdit?: boolean;
  companyId: string;
}

export const ActiveCourse = ({ menuExpanded, companyId, userData, subData, showEdit }: ActiveCourseProps) => {
  if (!userData?.subscription?.company) return null

  const navigate = useNavigate()

  const isAgentOfActiveCourse = isAgent(subData.user, userData.subscription.company.organizationId, companyId)
  const canInviteToActiveCourse = userData.subscription.company.peerInvitationMode === 'approval' || userData.subscription.company.peerInvitationMode === 'open' || isAgentOfActiveCourse

  const completion = userData.subscription.progress?.completion || 0
  const distributionParams = userData.subscription.company.subsDistributionParams.find(c => c.courseId === userData.subscription.courseId) || subsDistributionParamsFactory()
  const isExpired = isCourseExpired({
    absoluteDeadline: distributionParams.absoluteDeadline,
    startedAt: userData.subscription.progress?.startedAt || 0,
    relativeDeadline: distributionParams.relativeDeadline,
  })
  const isTestCompany = userData.subscription.company.companyType === 'test'
  const buttonText = isExpired
    ? intl.get('course_expired_button')
      : completion > 0
        ? completion >= userData.course.graduationGrade
          ? intl.get('open_course')
          : intl.get('continue_course', { 0: completion })
        : intl.get('start_now_course')

  if (!menuExpanded) {
    return (
      <div className="flex flex-col flex-1 items-center space-y-2">
        <Tippy placement="right" content={buttonText}>
          <button className="icon icon-play-light font-bold w-12 h-12 hover:text-coral" onClick={() => navigate('/learn')} />
        </Tippy>

        {/* {subData.user.editorCourses.includes(userData.course.id) && showEdit && (
          <Tippy id="edit-tip" placement="right" text="Switch to Edit Mode">
            <button className="icon icon-edit-light font-bold text-lg w-12 h-12 rounded-md hover:text-coral" onClick={() => updateAppCache('isEditing', true)} />
          </Tippy>
        )} */}
      </div>
    )
  }

  return (
    <div className="flex flex-col flex-1 px-4">
      <div className="uppercase font-bold mb-1 text-sm tracking-wide">{intl.get('current_program')}</div>

      <div className="w-full h-36 bg-gray-200 bg-center bg-no-repeat bg-cover relative rounded-md" style={{ backgroundImage: `url(${userData.course.imageUrl})` }}>
        {/* <LikeButton
          companyId={userData.subscription.companyId}
          courseId={userData.course.id}
          userId={subData.user.id}
          liked={subData.user.favoritedSubscriptions.includes(`${userData.subscription.companyId}-${userData.subscription.courseId}`)}
        /> */}
      </div>

      <div className="py-3 pb-0">
        <button
          id="recent-course-title"
          onClick={() => navigate(`/details/${userData.subscription.courseId}`)}
          className="text-left font-bold text-lg hover:text-actions-multiple_choice course-title">
          {userData.course.title}
        </button>

        <div className="text-sm text-left my-0.5">
          {userData.subscription.company.organizationName}
        </div>

        <div className="text-sm text-left">
          {stringSlicer(userData.subscription.company?.groupName, 19) || stringSlicer(userData.subscription.company?.name, 19) || 'Unknown Group'}
        </div>

        <div className="flex mt-3 mb-4 relative items-center">
          <div className="bg-lightwarmgray flex-1 h-2 rounded-md mr-4 relative">
            <span className="bg-lake h-2 rounded-md inline-block absolute left-0" style={{ width: `${userData.subscription.progress?.completion || 0}%` }} />
          </div>

          <MoreButton
            companyId={userData.subscription.companyId}
            courseId={userData.subscription.courseId}
            courseTitle={userData.subscription.course?.title || 'Program'}
            canInvite={canInviteToActiveCourse}
            isAgent={isAgentOfActiveCourse}
            isEditor={subData.user.editorCourses.includes(userData.subscription.courseId)}
            isAdmin={isAdmin(subData.user)}
            isSandbox={isTestCompany}
            groupName={userData.subscription.company.groupName || userData.subscription.company.name}
          />
        </div>

        <Button
          type="primary"
          icon="icon-play-light"
          fullWidth={true}
          text={buttonText}
          onClick={() => {
            if (buttonText.includes('Continue')){
              trackButtonEnhanced({
                button: 'Continue',
                onScreen: 'Home',
              })
            }
            navigate('/learn')
          }}
        />
      </div>
    </div>
  )
}
