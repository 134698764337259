import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import intl from 'react-intl-universal'
import { uniq } from 'ramda'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Popover } from 'react-tiny-popover'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'
import { Button } from 'components/utils/Button'
import { getCompaniesAllowedToDistributeCourseToQuery, GetCompaniesAllowedToDistributeCourseToRes, GetCompaniesAllowedToDistributeCourseToVars, getCompanyIdsCourseAlreadyDistributedToQuery, GetCompanyIdsCourseAlreadyDistributedToRes, GetCompanyIdsCourseAlreadyDistributedToVars } from 'graphql/queries/editor/getCompaniesAllowedToDistributeCourseTo'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { toggleOrgsCourseDistributionMutation, ToggleOrgsCourseDistributionRes, ToggleOrgsCourseDistributionVars } from 'graphql/mutations/editor/toggleOrgsCourseDistribution'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, getUserAndActiveCourseQuery, GetUserAndActiveCourseResponse, GetUserAndActiveCourseVariables, getUserQuery, GQLUserResponse } from 'graphql/queries/user/getUser'
import Toggle from 'components/utils/Toggle'
import PublishDialog, { getOrganizationsOrCmpsAllowedToPublishToQuery, OrganizationAllowPublishToRes, updateUserCourseNotificationsMutation, UpdateUserCourseNotificationsRes, UpdateUserCourseNotificationsVars } from 'components/course/Publish'
import { getCourseEditorsQuery, GetCourseEditorsRes, GetCourseEditorsVars } from 'graphql/queries/course/getCourseEditors'
import { track, trackButton, trackButtonEnhanced } from 'utils/track'
import { showModal, showQuickTip } from 'components/utils/CustomModal'
import { ShareCourse, Sidebar } from 'components/editor/course/Sidebar'
import { PageHeader } from 'components/home/PageHeader'
import { ensureOrganizationForUserMutation, EnsureOrganizationForUserVars } from 'graphql/mutations/user/ensureOrganizationForUser'
import { MiniLoader } from 'components/utils/MiniLoader'
import { CREATE_COMPANY, DELETE_COMPANY, UPDATE_COMPANY } from 'graphql/mutations/company/manageCompany'
import { Invite } from 'components/navbar/course-list/Header'
import { BASE_DASHBOARD_URL } from 'constants/General'
import { getOrganizationsQuery, GetOrganizationsRes, GetOrganizationsVars } from 'graphql/queries/organization/getOrganziations'
import { Company } from 'graphql/schemas/company/Company'
import { checkMeta, debounce, getMeta } from 'utils/functions'
import { manageProgramTourSteps } from 'joyrides/manage-program-tour'
import { useTour } from '@reactour/tour'
import { getCourseAvailabilityQuery, GetCourseAvailabilityRes } from 'graphql/queries/course/getCourseWithData'
import { updateMetaDeep } from 'actions/users_actions'
import { inviteByEmail } from 'actions/companies_actions'
import { getUserOrganizationCompanies2Query, GetUserOrganizationCompanies2Res, GetUserOrganizationCompanies2Vars } from 'graphql/queries/organization/getUserOrganizationCompanies2'
import { useLocation, useNavigate } from 'react-router-dom'
import { history } from 'utils/history'

const Publish = ({ currentCompanyType }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  // const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const userId = appData?.appState.loggedInAs.uid || ''

  const location = useLocation()
  const navigate = history.navigate || useNavigate()
  const [organizationCreated, setOrganizationCreated] = useState(false)
  const [currentTab, setCurrentTab] = useState(location.hash === '#share' ? intl.get('program_share_sandbox') : intl.get('general'))
  const [ensureOrganizationForUser] = useMutation<{ ensureOrganizationForUser: string }, EnsureOrganizationForUserVars>(ensureOrganizationForUserMutation)

  const tabs = [
    {
      name: intl.get('general'), icon: <svg className="w-4 h-4 mr-2 -mt-0.5" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9533 8.65301C12.98 8.43967 13 8.22634 13 7.99967C13 7.77301 12.98 7.55967 12.9533 7.34634L14.36 6.24634C14.4867 6.14634 14.52 5.96634 14.44 5.81967L13.1067 3.51301C13.0267 3.36634 12.8467 3.31301 12.7 3.36634L11.04 4.03301C10.6933 3.76634 10.32 3.54634 9.91335 3.37967L9.66002 1.61301C9.64002 1.45301 9.50002 1.33301 9.33335 1.33301H6.66668C6.50002 1.33301 6.36002 1.45301 6.34002 1.61301L6.08668 3.37967C5.68002 3.54634 5.30668 3.77301 4.96002 4.03301L3.30002 3.36634C3.14668 3.30634 2.97335 3.36634 2.89335 3.51301L1.56001 5.81967C1.47335 5.96634 1.51335 6.14634 1.64002 6.24634L3.04668 7.34634C3.02002 7.55967 3.00002 7.77967 3.00002 7.99967C3.00002 8.21967 3.02002 8.43967 3.04668 8.65301L1.64002 9.75301C1.51335 9.85301 1.48001 10.033 1.56001 10.1797L2.89335 12.4863C2.97335 12.633 3.15335 12.6863 3.30002 12.633L4.96002 11.9663C5.30668 12.233 5.68002 12.453 6.08668 12.6197L6.34002 14.3863C6.36002 14.5463 6.50002 14.6663 6.66668 14.6663H9.33335C9.50002 14.6663 9.64002 14.5463 9.66002 14.3863L9.91335 12.6197C10.32 12.453 10.6933 12.2263 11.04 11.9663L12.7 12.633C12.8533 12.693 13.0267 12.633 13.1067 12.4863L14.44 10.1797C14.52 10.033 14.4867 9.85301 14.36 9.75301L12.9533 8.65301V8.65301ZM8.00002 10.333C6.71335 10.333 5.66668 9.28634 5.66668 7.99967C5.66668 6.71301 6.71335 5.66634 8.00002 5.66634C9.28668 5.66634 10.3333 6.71301 10.3333 7.99967C10.3333 9.28634 9.28668 10.333 8.00002 10.333Z" fill={currentTab === intl.get('general') ? '#1B585E' : '#4A4A4A'} />
      </svg>,
    },
    {
      name: intl.get('program_share_sandbox'), icon: <i className="icon icon-share-light font-bold mr-2 -mt-0.5 w-4 h-4" />,
    },
    {
      name: intl.get('invite_curators'), icon: <svg className="w-4 h-4 mr-2 -mt-0.5" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 11.6397V13.6664C2 13.853 2.14667 13.9997 2.33333 13.9997H4.36C4.44667 13.9997 4.53333 13.9664 4.59333 13.8997L11.8733 6.62638L9.37333 4.12638L2.1 11.3997C2.03333 11.4664 2 11.5464 2 11.6397ZM13.8067 4.69305C14.0667 4.43305 14.0667 4.01305 13.8067 3.75305L12.2467 2.19305C11.9867 1.93305 11.5667 1.93305 11.3067 2.19305L10.0867 3.41305L12.5867 5.91305L13.8067 4.69305Z" fill={currentTab === intl.get('invite_curators') ? '#1B585E' : '#4a4a4a'} />
      </svg>,
    },
  ]

  useEffect(() => {
    ensureOrganizationForUser({
      variables: { userId },
      onCompleted: () => setOrganizationCreated(true),
    })
  }, [])

  useEffect(() => {
    if (location.hash === '#share') {
      setCurrentTab(intl.get('program_share_sandbox'))
    }
  }, [location.hash],
  )

  if (!organizationCreated) {
    return (
      <div className="p-8 text-center">
        <MiniLoader />
      </div>
    )
  }

  return (
    <div className="px-6 max-w-5xl">
      <PageHeader title={intl.get('global_manage_program')} hideBorder={true} showBack={false} />

      <section>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            {intl.get('select_tab')}
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
            defaultValue={0}
            onChange={v => {
              navigate(`#${v.currentTarget.value}`)
              setCurrentTab(v.currentTarget.value)
            }}>
            {tabs.map(tab => (
              <option key={tab.name} value={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab, index) => (
                <button
                  key={tab.name}
                  onClick={() => {
                    navigate((`#${tab.name}`).toLowerCase().split(' ')[0])
                    setCurrentTab(tab.name)
                  }
                  }
                  className={classNames(
                    currentTab === tab.name
                      ? 'border-actions-multiple_choice text-actions-multiple_choice'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'group inline-flex items-center pb-4 px-1 border-b-2 font-medium text-sm',
                  )}
                  aria-current={currentTab === tab.name ? 'page' : undefined}>
                  {tab.icon}
                  <span>{tab.name}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
      </section>

      <section className="bg-white mt-4 p-6 border border-lightwarmgray rounded-md">
        {currentTab === intl.get('general') && (
          <Manage />
        )}

        {currentTab === intl.get('program_share_sandbox') && (
          <Distribute currentCompanyType={currentCompanyType} />
        )}

        {currentTab === intl.get('invite_curators') && (
          <Curators />
        )}
      </section>
    </div>
  )
}

const Manage = () => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const userId = appData?.appState.loggedInAs.uid || ''
  const { data: courseData, loading: courseLoading } = useQuery<GetUserAndActiveCourseResponse, GetUserAndActiveCourseVariables>(getUserAndActiveCourseQuery, {
    skip: !appData || !userId || !appData.appState.currentCompanyIdCourseId,
    variables: {
      companyId,
      courseId,
      userId,
    },
    errorPolicy: 'all',
  })
  const { data: userData, loading: userLoading, refetch } = useQuery<GQLUserResponse>(getUserQuery, { variables: { userId }, skip: !appData || !userId || !appData.appState.currentCompanyIdCourseId })
  const [toggleNotifications] = useMutation<UpdateUserCourseNotificationsRes, UpdateUserCourseNotificationsVars>(updateUserCourseNotificationsMutation, {
    onCompleted: () => {
      toast.success('Much success!')
      setTimeout(refetch, 2500)
    },
  })
  const [enabledNotifications, setEnabledNotifications] = useState(userData?.user.editorCourseNotifications?.find(c => c.courseId === courseId)?.distributionStatusUpdates || false)

  useEffect(() => {
    setEnabledNotifications(userData?.user.editorCourseNotifications?.find(c => c.courseId === courseId)?.distributionStatusUpdates || false)
  }, [userData?.user.editorCourseNotifications])

  if (courseLoading || userLoading) return <div>{intl.get('loading')}</div>

  return (
    <div>
      <PageTitle title={intl.get('program_settings')} subtitle={intl.get('manage_program_general_message')} />

      <div className="my-4 flex justify-between">
        <div>
          <div className="font-bold text-lg">{intl.get('course_name')}</div>
          <p>{courseData?.course.title}</p>

          <div className="font-bold text-lg">{intl.get('internal_id')}</div>
          <p>{courseData?.course.id}</p>
        </div>

        <div className="rounded-md inline-block">
          <img src={courseData?.course.imageUrl || undefined} className="w-24 h-24 rounded-md" />
        </div>
      </div>

      <Toggle
        id="notification-toggle"
        label={intl.get('publish_pending_notify')}
        defaultChecked={enabledNotifications}
        onChange={() => {
          toggleNotifications({
            variables: {
              courseId,
              userId,
              update: {
                distributionStatusUpdates: !enabledNotifications,
              },
            },
          })
          setEnabledNotifications(!enabledNotifications)
        }}
      />

      {userData?.user.editorCourses.includes(courseId) && (
        <Sidebar
          courseKey={appData?.appState.currentCompanyIdCourseId || ''}
          menuExpanded={true}
          v2={true}
        />
      )}
    </div>
  )
}

const Distribute = ({ currentCompanyType }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const userId = appData?.appState.loggedInAs.uid || ''
  const [search, setSearch] = useState<string | null>(null)

  const { data: currentSubData, loading: userLoading, error: userError } = useQuery<GetCurrentSubscriptionRes>(getCurrentSubscriptionQuery, {
    variables: { userId },
  })

  const { data: distribData, refetch: distribRefetch } = useQuery<GetCompanyIdsCourseAlreadyDistributedToRes, GetCompanyIdsCourseAlreadyDistributedToVars>(
    getCompanyIdsCourseAlreadyDistributedToQuery,
    { variables: { courseId }, nextFetchPolicy: 'network-only' },
  )

  const activeWorkspaceId = getMeta(currentSubData?.user.metadata, 'activeWorkspaceId')

  const { data, loading, refetch } = useQuery<GetCompaniesAllowedToDistributeCourseToRes, GetCompaniesAllowedToDistributeCourseToVars>(
    getCompaniesAllowedToDistributeCourseToQuery,
    {
      variables: {
        userId,
        courseId,
        searchText: '',
        skip: 0,
        limit: 100,
        organizationId: currentCompanyType === 'test' ? null : activeWorkspaceId,
      },
      nextFetchPolicy: 'network-only',
    },
  )

  const { data: pubData, refetch: refetchPubData } = useQuery<OrganizationAllowPublishToRes, { courseId: string, userId: string }>(getOrganizationsOrCmpsAllowedToPublishToQuery, {
    variables: { courseId, userId },
    nextFetchPolicy: 'network-only',
  })

  const { data: orgData } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToManagerOrganizations: userId,
    },
  })

  const { data: courseData, loading: courseLoading } = useQuery<GetUserAndActiveCourseResponse, GetUserAndActiveCourseVariables>(getUserAndActiveCourseQuery, {
    skip: !appData || !userId || !appData.appState.currentCompanyIdCourseId,
    variables: {
      companyId,
      courseId,
      userId,
    },
    errorPolicy: 'all',
  })

  const { data: courseAvailabilityData, refetch:refetchCourseAvailabilityData } = useQuery<GetCourseAvailabilityRes>(getCourseAvailabilityQuery, {
    skip: !appData,
    variables: { courseId },
    fetchPolicy: 'network-only',
  })

  const [toggleDistribution] = useMutation<ToggleOrgsCourseDistributionRes, ToggleOrgsCourseDistributionVars>(toggleOrgsCourseDistributionMutation, {
    onCompleted: (res) => {
      track({
        event: 'Program Distributed',
        variables: {
          course_id: courseId,
          user_id: userId,
        },
      })

      // toast.success('Much success!')
      setDistributionChanges(new Map())
      // refetchAllData(5000)
    },
    onError: (err) => {
      toast.error(`Error - ${err.message}`)
      console.error(err)
    },
  })

  const checkbox = useRef<any>(null)
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState<any>([])
  const [distributionChanges, setDistributionChanges] = useState<Map<string, { addTo: string[], removeFrom: string[] }>>(new Map())

  const [refetching, setRefetching] = useState(false)
  const [pagination, setPagination] = useState({ limit: 10, skip: 0 })
  const [quicktipShown, setQuicktipShown] = useState(false)
  const debounceQuickTip = debounce(() => showQuickTip('firstDistribute', 'Click the "Save changes" button to save your changes.', () => null), 100)
  const _deb = useRef<any>(null)
  const _debounceSaveChanges = useRef<any>(null)
  const { setIsOpen, isOpen, setCurrentStep, setSteps } = useTour()
  const affectedGroups = Array.from(distributionChanges, ([key, value]) => value.addTo.length > 0 || value.removeFrom.length > 0).reduce((partialSum, a) => partialSum + Number(a), 0)

  useEffect(() => {
    if (!checkMeta(currentSubData?.user.metadata, '/general/tour/gnowbeWebManageProgramSeen') && appData?.appState.currentTour !== 'checkListTour') {
      setCurrentStep(0)
      setSteps(manageProgramTourSteps)
      setTimeout(() => {
        setIsOpen(true)
        updateMetaDeep('/tour/gnowbeWebManageProgramSeen', 'true', true, 'tour')
      }, 500)
    }
    if (!checkMeta(currentSubData?.user.metadata, '/general/tour/gnowbeWebManageProgramSeen') && appData?.appState.currentTour === 'checkListTour') {
      updateMetaDeep('/tour/gnowbeWebManageProgramSeen', 'true', true, 'tour')
    }
  }, [])

  useLayoutEffect(() => {
    const isIndeterminate = selectedGroups.length > 0 && selectedGroups.length < (data?.res.companies.length || 0)
    setChecked(selectedGroups.length === data?.res.companies.length)
    setIndeterminate(isIndeterminate)
    if (checkbox?.current) checkbox.current.indeterminate = isIndeterminate
  }, [selectedGroups])

  const toggleAll = () => {
    if (appData?.appState.currentTour !== 'checkListTour' && !quicktipShown && !checkMeta(currentSubData?.user.metadata, '/general/tips/firstDistribute')) {
      setQuicktipShown(true)
      debounceQuickTip()
    }

    const unchecked = checked || indeterminate
    const companies = data?.res.companies || []
    setSelectedGroups(unchecked ? [] : companies.map(p => p.id))
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)

    const cmpMap = distributionChanges
    companies.forEach((company) => {
      cmpMap.set(company.organizationId, {
        addTo: unchecked ? [] : companies.filter(c => c.organizationId === company.organizationId && !distribData?.res.includes(c.id)).map(p => p.id),
        removeFrom: unchecked ? companies.filter(c => c.organizationId === company.organizationId && distribData?.res.includes(c.id)).map(p => p.id) : [],
      })
    })
    setDistributionChanges(cmpMap)
  }

  const toggle = (checked: boolean, company: Company) => {
    if (courseData?.course.hasAssessments && !company.organization?.featureFlags.includes('assessments')) {
      toast.error('Can\'t distribute a program with assessments to a group without the assessment feature.')
      return
    }

    if (appData?.appState.currentTour !== 'checkListTour' && !quicktipShown && !checkMeta(currentSubData?.user.metadata, '/general/tips/firstDistribute')) {
      setQuicktipShown(true)
      debounceQuickTip()
    }

    setSelectedGroups(
      checked
        ? [...selectedGroups, company.id]
        : selectedGroups.filter(p => p !== company.id),
    )

    const cmpMap = distributionChanges
    const companies = distribData?.res || []
    const currentOrganization = cmpMap.get(company.organizationId) || { addTo: checked ? [company.id] : [], removeFrom: !checked ? [company.id] : [] }
    let addTo: string[] = []
    let removeFrom: string[] = []
    if (checked) {
      addTo = !companies.includes(company.id)
        ? uniq(currentOrganization.addTo.concat(company.id))
        : currentOrganization.addTo
      removeFrom = currentOrganization.removeFrom.filter(c => c !== company.id)
    }
    else {
      addTo = uniq(currentOrganization.addTo.filter(c => c !== company.id))
      removeFrom = companies.includes(company.id)
        ? uniq(currentOrganization.removeFrom.concat(company.id))
        : currentOrganization.removeFrom
    }
    cmpMap.set(company.organizationId, {
      addTo,
      removeFrom,
    })
    setDistributionChanges(cmpMap)

    // _debounceSaveChanges.current = clearTimeout(_debounceSaveChanges.current)
    // _debounceSaveChanges.current = setTimeout(() => {
    //   saveChanges()
    // }, 1500)
  }

  const saveChanges = () => {
    showModal({
      title: intl.get('confirm_changes'), // intl.get('share_with_group_confirm_dialog_title'),
      component: (
        <>
          <div>{intl.get('changes_upon_confirmation')}</div>
          <div className="mt-4">
            {intl.get('your_program_will_be')}
            <ul className="list-disc pl-8">
              {distributeToNr > 0 && <li>{intl.get('shared_with_groups', { 0: distributeToNr })}</li>}
              {undistributeFromNr > 0 && <li>{intl.get('unsheared_from_groups', {0: undistributeFromNr})}</li>}
            </ul>
            {/* {affectedGroups > 1 ? 'These' : 'This'} change{affectedGroups > 1 ? 's' : ''} will affect <strong>{affectedGroups}</strong> groups. */}
          </div>
        </>
      ), // intl.get('share_with_group_confirm_dialog_text'),
      primaryAction: () => {
        track({
          event: 'Program Published',
          variables: {
            course_id: courseId,
            user_id: userId,
            to: distributeToNr,
            from: undistributeFromNr,
          },
        })

        toggleDistribution({
          variables: {
            courseId,
            organizations: Array.from(distributionChanges, ([key, value]) => ({
              organizationId: key,
              addTo: { onlyCompanyIds: value.addTo },
              removeFrom: { onlyCompanyIds: value.removeFrom },
            })),
            distributionParams: {
              courseId,
            },
            onlyVisibleInAnalyticsInMetrics: false,
          },
        })

      },
      primaryText: intl.get('yes'),
      secondaryText: intl.get('no'),
    })
  }

  const isCompanyDirty = (companyId) => {
    let dirty = false
    distributionChanges.forEach((c) => {
      if (c.addTo.includes(companyId) || c.removeFrom.includes(companyId)) dirty = true
    })
    return dirty
  }

  const refetchAllData = (delay?: number) => {
    setRefetching(true)
    setTimeout(() => {
      refetchPubData()
      refetch()
      distribRefetch()
    }, delay || 0)

    setTimeout(() => {
      setRefetching(false)
    }, 2500)
  }

  useEffect(() => {
    if (!distribData) return

    setSelectedGroups(distribData.res)
  }, [distribData])

  useEffect(() => {
    _deb.current = clearTimeout(_deb.current)
    _deb.current = setTimeout(() => {
      setPagination({ limit: 10, skip: 0 })
      refetch({ searchText: search || '' })
    }, 1000)
  }, [search])

  const distributeToNr = Array.from(distributionChanges, ([key, value]) => value.addTo.length).reduce((partialSum, a) => partialSum + a, 0)
  const undistributeFromNr = Array.from(distributionChanges, ([key, value]) => value.removeFrom.length).reduce((partialSum, a) => partialSum + a, 0)
  const isDirty = isOpen || affectedGroups > 0
  const isPublisherOfMultipleOrgs = true // pubData && (pubData.res.length > 1 || pubData.res.some(r => (r.companies || []).length > 0)) || false
  const companies = data?.res.companies.filter(c => !c.disabled && c.companyType !== 'public' && c.companyType !== 'test') || []
  const companiesFiltered = companies.filter(company => company.name.toLocaleLowerCase().includes(search ? search.toLocaleLowerCase() : ''))
  const companiesPage = companiesFiltered.slice(pagination.skip, pagination.limit  + pagination.skip)
  const isAvailableToOrg = courseAvailabilityData?.course.availableToOrganizations.includes(activeWorkspaceId) || false

  return (
    <div>
      {isPublisherOfMultipleOrgs && (
        <div id="manage-program-availability" className="mb-8">
          <PageTitle title={intl.get('manage_program_share_availability')} subtitle={intl.get('manage_program_share_availability_text')} />
          {/* todo: add "available/unavailable for sharing" next to the toggle */}
          <PublishDialog currentCompanyType={currentCompanyType} v2={true} refetchQuery={() => {
            refetchPubData()
            refetch()
          }} onClose={() => null} />
        </div>
      )}

      {/* <Prompt
        when={affectedGroups > 0}
        message={`You have unsaved changes. Press 'OK' to leave without saving, or 'Cancel' to go back and save your work.`}
      /> */}

      {(currentSubData?.user.agentCompanies.length || 0) > 0 || (currentSubData?.user.managerOrganizations.length || 0) > 0
        ? <div id="share-your-program">
            <PageTitle
              title={intl.get('share_with_group')}
              subtitle={intl.get('share_with_group_text')}
            />

          <div className="text-sm text-gray-700 -mt-2">
            {intl.getHTML('add_remove_group_to_program')}
          </div>

            <div className="mt-4 flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <div>
                  <input type="text" placeholder={intl.get('filter_members_hint')} className="form-input w-64" onChange={e => setSearch(e.target.value)} />
                </div>

              <div className="flex gap-2">
                {isDirty
                  ? <>
                    <Button
                      // type="danger"
                      size="small"
                      text="Undo"
                      svgIcon={<svg width="18" height="9" viewBox="0 0 18 9" className="mr-2 -mt-0.5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.41675 1.66683C7.20841 1.66683 5.20841 2.49183 3.66675 3.8335L0.666748 0.833496V8.3335H8.16675L5.15008 5.31683C6.30841 4.35016 7.78341 3.75016 9.41675 3.75016C12.3667 3.75016 14.8751 5.67516 15.7501 8.3335L17.7251 7.6835C16.5667 4.19183 13.2917 1.66683 9.41675 1.66683Z" fill="#B50700" />
                      </svg>
                      }
                      className="border-coral bg-white text-coral hover:bg-red-50"
                      onClick={() => {
                        setDistributionChanges(new Map())
                        setSelectedGroups(distribData?.res || [])
                      }}
                    />

                    <Button
                      id="distribute-btn"
                      type="primary"
                      size="small"
                      icon="icon-check-light"
                      text={intl.get('save_changes')}
                      onClick={saveChanges}
                    />
                  </>
                  : <>
                    <Button
                      text={refetching ? intl.get('global_refreshing') : intl.get('global_refresh_data')}
                      size="small"
                      disabled={refetching}
                      onClick={() => {
                        refetchAllData()
                      }}
                    />

                    {(orgData?.res.organizations || []).length > 0 && (
                      <Button
                        type="team"
                        text={`+ ${intl.get('share_with_group_create_group')}`}
                        size="small"
                        id="share-with-create-group-button"
                        onClick={() => {
                          if (!currentSubData?.user.managerOrganizations.includes(activeWorkspaceId)) {
                            const modal = showModal({
                              title: intl.get('can_not_create_new_groups'),
                              component: <div>
                                 {intl.get('switch_worskpace_to_create_group')}
                              </div>,
                              primaryText: 'Close',
                              primaryAction: () => {
                                modal.close()
                              },
                              secondaryButton: false,
                            })
                            return
                          }
                          const modal = showModal({
                            title: intl.get('share_with_group_create_group_dialog_create_btn'),
                            component: <CreateGroupModal close={() => modal.close()} activeWorkspaceId={activeWorkspaceId} />,
                            hideFooter: true,
                            className: 'no-content-margin',
                          })
                        }}
                      />
                    )}
                  </>
                }
                </div>
              </div>
            </div>

            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {refetching && (
                    <div className="absolute inset-0 bg-gray-50 opacity-75 flex justify-center items-center">
                      <MiniLoader />
                    </div>
                  )}

                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                          {companies.length === 0 && (
                            <input
                              id="all-groups-checkbox"
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lake focus:ring-lake sm:left-6"
                              onChange={(e) => e.preventDefault}
                            />
                          )}
                          {companies.length > 0 && (
                            <input
                              id="all-groups-checkbox"
                              ref={checkbox}
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lake focus:ring-lake sm:left-6"
                              checked={checked}
                              onChange={toggleAll}
                            />
                          )}
                        </th>

                        <th scope="col" className="min-w-[12rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                          {intl.get('share_with_group_group_group_name')}
                        </th>

                        <th scope="col" className="w-8 py-3.5 px-3 text-center text-sm font-semibold text-gray-900">
                          {intl.get('participants')}
                        </th>

                        {isPublisherOfMultipleOrgs && (
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize">
                            {intl.get('onboarding_organization')}
                          </th>
                        )}

                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Available for
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Available until
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Manual sponsorship
                          </th> */}
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          {intl.get('manage_program_curators_actions')}
                        </th>
                      </tr>
                    </thead>

                      <tbody className="divide-y divide-gray-200 bg-white">
                        {loading
                          ? <tr><td colSpan={5} className="py-8 px-3 text-sm font-medium text-gray-900 text-center">{intl.get('loading')}</td></tr>
                          : companies.length <= 0
                            ? <tr><td colSpan={5} className="py-8 px-3 text-sm font-medium text-gray-900 text-center">
                                {isAvailableToOrg ? intl.get('share_with_group_empty') : 'Make available to the workspace first!'}
                              </td></tr>
                            : companiesPage
                            .map((company, companyIdx) => {
                              // if (search && !company.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) return null

                              const trCn = classNames({
                                'bg-gray-50': !(companyIdx % 2 === 0),
                                // 'bg-gray-100': selectedGroups.includes(company.id),
                                'cursor-pointer': true,
                              })
                              const isChecked = selectedGroups.includes(company.id)
                              const isCmpDirty = !!Array.from(distributionChanges, ([key, value]) => value.addTo.includes(company.id) || value.removeFrom.includes(company.id)).filter(Boolean).length

                              return (
                                <tr key={company.id} className={trCn}>
                                  <td className="relative w-12 px-6 sm:w-16 sm:px-8" onClick={e => toggle(!isChecked, company)}>
                                    {/* {(isChecked || isCompanyDirty(company.id)) && (
                                      <div className={`absolute inset-y-0 left-0 w-0.5 ${isCompanyDirty(company.id) ? 'bg-red-600' : 'bg-lake'}`} />
                                    )} */}
                                    <input
                                      id={companyIdx === 0 ? 'first-distrib-checkbox' : undefined}
                                      type="checkbox"
                                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lake focus:ring-lake sm:left-6"
                                      value={company.id}
                                      checked={isChecked}
                                      onChange={e => toggle(e.target.checked, company)}
                                    />
                                    {isCompanyDirty(company.id) && (<span className="absolute inline-block top-4 right-5 w-2 h-2 rounded-full bg-coral" />)}
                                  </td>

                                  <td className={classNames(
                                    'whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 truncate max-w-xs',
                                    // isChecked ? 'text-green-600' : 'text-gray-900',
                                  )} onClick={e => toggle(!isChecked, company)}>
                                    {company.name}
                                  </td>

                                  <td className={classNames(
                                    'whitespace-nowrap py-4 px-3 text-sm text-center text-gray-900',
                                    // isChecked ? 'text-green-600' : 'text-gray-900',
                                  )}>
                                    <div className="flex">
                                      <div className="m-auto w-1/3" onClick={e => toggle(!isChecked, company)}>{company.usersCount}</div>
                                      <button
                                        id="invite-learners-button"
                                        onClick={() => {
                                          if (isDirty) {
                                            return showModal({
                                              title: intl.get('dialog_unsaved_changes_title'),
                                              content: intl.get('unsaved_changes_invite_to_group'),
                                              primaryText: intl.get('save_changes'),
                                              secondaryText: intl.get('global_cancel'),
                                              primaryAction: () => saveChanges(),
                                            })
                                          }
                                          if (!isChecked) {
                                            setTimeout(() => {
                                              toggleDistribution({
                                                variables: {
                                                  courseId,
                                                  organizations: [{
                                                    organizationId: company.organizationId,
                                                    addTo: { onlyCompanyIds: [company.id] },
                                                    removeFrom: { onlyCompanyIds: [] },
                                                  }],
                                                  distributionParams: {
                                                    courseId,
                                                  },
                                                  onlyVisibleInAnalyticsInMetrics: false,
                                                },
                                              })
                                            }, 1000)
                                          }

                                          const modal = showModal({
                                            title: intl.get('home_group_invite'),
                                            component: <Invite companyId={company.id} home={true} groupName={company.name} courseName={courseData?.course.title} isAgent={true} courseId={courseId} orgId={company.organizationId} close={() => modal.close()} isPublish={true}/>,
                                            secondaryButton: false,
                                            primaryText: intl.get('global_close'),
                                            hideFooter: true,
                                            className: 'add-action-modal',
                                          })
                                          setTimeout(() => {
                                            distribRefetch()
                                          }, 3000)
                                        }}
                                        className="flex items-center w-2/3 text-gray-700 px-2 py-1 text-sm hover:bg-[#E3E4E5] border border-[#A0A2A6] rounded-2xl ml-7">
                                        <svg className="w-5 h-5 inline-block mr-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M15.8333 10.8333H10.8333V15.8333H9.16663V10.8333H4.16663V9.16663H9.16663V4.16663H10.8333V9.16663H15.8333V10.8333Z" fill="#101010" />
                                        </svg> {intl.get('invite')}
                                      </button>
                                    </div>
                                  </td>

                                  {isPublisherOfMultipleOrgs && (
                                    <td className={classNames(
                                      'whitespace-nowrap py-4 px-3 text-sm text-gray-900',
                                      // isChecked ? 'text-green-600' : 'text-gray-900',
                                    )} onClick={e => toggle(!isChecked, company)}>
                                      {company.organizationName}
                                    </td>
                                  )}

                                  {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">asd</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">asd</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">asd</td> */}
                                  <td className="py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                                    <CompanyMenu
                                      first={companyIdx === 0}
                                      organizationId={company.organizationId}
                                      companyId={company.id}
                                      groupName={company.name}
                                      isDirty={isCmpDirty}
                                      saveChanges={() => {
                                        saveChanges()
                                      }}
                                      refresh={() => {
                                        refetchPubData()
                                        refetch()
                                      }}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                      }

                      <tr>
                        <td colSpan={5}>
                          <nav
                            className="flex items-center justify-between px-4 py-2"
                            aria-label="Pagination">
                            <div className="hidden sm:block">
                              <p className="text-sm text-gray-700 pb-0">
                                {intl.getHTML('showing_results_to_of', {0: companiesFiltered.length > 0 ? pagination.skip + 1 : 0, 1: Math.min(pagination.skip + pagination.limit, companiesFiltered.length), 2: companiesFiltered.length})}
                              </p>
                            </div>
                            <div className="flex-1 flex justify-between sm:justify-end">
                              <Button
                                text={intl.get('global_previous')}
                                disabled={pagination.skip === 0}
                                onClick={() => setPagination({ ...pagination, skip: Math.max(0, pagination.skip - pagination.limit) })}
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              />
                              <Button
                                text={intl.get('next_text')}
                                onClick={() => setPagination({ ...pagination, skip: pagination.skip + pagination.limit })}
                                disabled={(pagination.skip + pagination.limit) >= companiesFiltered.length}
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              />
                            </div>
                          </nav>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        : null
      }
    </div>
  )
}

const Curators = () => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const userId = appData?.appState.loggedInAs.uid || ''

  const { data, loading, refetch } = useQuery<GetCourseEditorsRes, GetCourseEditorsVars>(getCourseEditorsQuery, { variables: { courseId } })

  const [search, setSearch] = useState<string | null>(null)
  const [removing, setRemoving] = useState<string[]>([])

  const TOGGLE_EDITOR = gql`
    mutation ToggleCourseEditor($courseId: String!, $userIdOrEmail: String!, $addRemove: AddRemove!, $noEmail: Boolean) {
      toggleRes: toggleCourseEditor(courseId: $courseId, userIdOrEmail: $userIdOrEmail, addRemove: $addRemove, noEmail: $noEmail) {
        key
        addRemove
        user {
          ... on User {
            id
            email
          }

          ... on UserInvited {
            email
          }
        }
      }
    }
  `

  const [toggleEditor] = useMutation(TOGGLE_EDITOR, {
    onCompleted: (data) => {
      track({
        event: 'Co-curator removed',
        variables: {
          programId: courseId,
          removedById: appData?.appState?.loggedInAs?.uid || '' ,
          action: 'remove',
          removedUserId: data.toggleRes.user.id,
          removedUserEmail: data.toggleRes.user.email,
        },
      })
    }
  })

  const invite = () => {
    trackButton({
      button: 'share_course',
      on_screen: 'publish',
    })

    trackButtonEnhanced({
      button: 'Share Program',
      onScreen: 'Publish',
    })

    const modal = showModal({
      title: intl.get('invite_co_curator_title'),
      component: (
        <div className="mt-4">
          <ShareCourse
            courseId={courseId}
            onClose={() => {
              modal.close()
              setTimeout(() => {
                refetch()
              }, 1000)
            }}
            courseName={null}
          />
        </div>
      ),
      hideFooter: true,
      className: 'no-content-margin',
    })
  }

  const curators = data?.res.users || []

  return (
    <div>
      <PageTitle title={intl.get('invite_curators')} subtitle={intl.get('curators_subtitle')} />

      <div className="mt-4 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <div>
            <input type="text" placeholder={intl.get('filter_members_hint')} className="form-input w-64" onChange={e => setSearch(e.target.value)} />
          </div>

          <Button
            type="team"
            text={`+ ${intl.get('invite_co_curator_title')}`}
            size="small"
            onClick={invite}
          />
        </div>

        <div className="-my-2 -mx-4 overflo x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th scope="col" className="min-w-[12rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                      {intl.get('manage_program_curators_name')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {intl.get('email')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      {intl.get('manage_program_curators_actions')}
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading
                    ? <tr><td colSpan={3} className="py-8 px-3 text-sm font-medium text-gray-900 text-center">{intl.get('loading')}</td></tr>
                    : curators.length <= 0
                      ? <tr><td colSpan={3} className="py-8 px-3 text-sm font-medium text-gray-900 text-center">{intl.get('click_invite_co_curator')}</td></tr>
                      : curators.map((user, i) => {
                        if (search && !user.profile.fullName?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())) return null

                        return (
                          <tr key={user.id} className={i % 2 !== 0 ? 'bg-gray-50' : undefined}>
                            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900">{user.profile.fullName}</td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900">{user.email}</td>
                            <td className="whitespace-nowrap py-1 px-3 text-sm text-gray-900 text-center">
                              <Button
                                text={removing.includes(user.id) ? intl.get('global_removing') : intl.get('global_remove')}
                                size="small"
                                icon="icon-trash-alt-light"
                                disabled={removing.includes(user.id) || user.id === userId}
                                onClick={() => {
                                  showModal({
                                    title: 'Are you sure?',
                                    content: `Are you sure you would like to remove ${user.email} from the list of curators?`,
                                    primaryText: 'Yes, remove',
                                    destructive: true,
                                    primaryAction: () => {
                                      setRemoving([...removing, user.id])
                                      toggleEditor({
                                        variables: {
                                          courseId,
                                          userIdOrEmail: user.id,
                                          addRemove: 'remove',
                                          noEmail: false,
                                        },
                                        update: () => {
                                          setTimeout(() => {
                                            toast.success('Much success!')
                                            refetch()
                                            setRemoving(removing.filter(id => id !== user.id))
                                          }, 1000)
                                        },
                                        onError: () => {
                                          toast.error('Error!')
                                        },
                                      })
                                    },
                                  })
                                }}
                              />
                              {/* <DotsVerticalIcon className="w-4 inline-block" /> */}
                            </td>
                          </tr>
                        )
                      })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Publish

const PageTitle = ({ title, subtitle }) => {
  return (
    <div>
      <div className="text-xl font-medium text-actions-multiple_choice">{title}</div>
      <p className="mt-1 text-sm text-gray-700">
        {subtitle}
      </p>
    </div>
  )
}

const CompanyMenu = ({ first, organizationId, companyId, groupName, isDirty, saveChanges, refresh }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [updateCompany] = useMutation(UPDATE_COMPANY)
  const [deleteCompany] = useMutation(DELETE_COMPANY)

  const menuCn = classNames({
    'w-52 custom-dropdown z-10': true,
  })

  return (
    <Popover
      isOpen={isOpen}
      positions={['left', 'right']}
      padding={10}
      align="start"
      onClickOutside={() => setIsOpen(false)}
      content={() => (
        <Transition
          show={true}
          appear={true}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <div className={menuCn}>
            <button
              onClick={() => {
                setIsOpen(false)
                showModal({
                  title: intl.get('share_with_group_rename_group'),
                  content: `${intl.get('share_with_group_rename_group_dialog_enter')}:`,
                  prompt: true,
                  value: groupName,
                  primaryAction: (name) => {
                    updateCompany({
                      variables: {
                        companyId,
                        companyUpdate: {
                          name,
                        },
                      },
                    })
                  },
                  primaryText: intl.get('share_with_group_rename_group'),
                })
              }}
              className="flex items-center w-full text-gray-700 px-4 py-2 text-sm hover:bg-gray-100">
              <svg className="w-5 h-5 inline-block mr-2 -mt-0.5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 14.375V17.5H5.625L14.8417 8.28334L11.7167 5.15834L2.5 14.375ZM17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C14.9833 2.41667 14.4583 2.41667 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667Z" fill="#101010" />
              </svg> {intl.get('share_with_group_rename_group')}
            </button>

            <button
              onClick={() => {
                setIsOpen(false)
                window.open(`${BASE_DASHBOARD_URL}/organization/${organizationId}/company/${companyId}`, '_blank')
              }}
              className="flex items-center w-full text-gray-700 px-4 py-2 text-sm hover:bg-gray-100">
              <svg className="w-5 h-5 inline-block mr-2 -mt-0.5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.16663 7.66663H6.66663V15.8333H4.16663V7.66663ZM8.83329 4.16663H11.1666V15.8333H8.83329V4.16663V4.16663ZM13.5 10.8333H15.8333V15.8333H13.5V10.8333Z" fill="#101010" />
              </svg> {intl.get('view_stats')} <svg className="w-4 h-4 inline-block ml-2 -mt-0.5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z" fill="#888" />
              </svg>

            </button>

            <button
              onClick={() => {
                setIsOpen(false)
                showModal({
                  title: intl.get('share_with_group_delete_group_dialog_title'),
                  component: (
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: intl.get('share_with_group_delete_group_dialog_text') }} />
                      <div className="mt-4">{intl.getHTML('type_delete_to_remove_group')}</div>
                    </div>
                  ),
                  prompt: true,
                  destructive: true,
                  primaryAction: (confString) => {
                    if (String(confString).toUpperCase() === 'DELETE') {
                      if (confirm('Are you really sure?')) {
                        deleteCompany({
                          variables: {
                            companyId,
                          },
                          update: () => {
                            setTimeout(() => {
                              toast.success('Much success!')
                              refresh()
                            }, 1000)
                          },
                        })
                      }
                    }
                    else {
                      alert('The group will NOT be deleted because you didn\'t write DELETE (in uppercase).')
                    }
                  },
                  primaryText: intl.get('share_with_group_delete_group'),
                })
              }}
              className="flex items-center w-full text-red-900 px-4 py-2 text-sm hover:bg-gray-100">
              <svg className="w-5 h-5 inline-block mr-2 -mt-0.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#B50700" />
              </svg> {intl.get('share_with_group_delete_group')}
            </button>
          </div>
        </Transition>
      )}>

      <button
        id={first ? 'first-distrib-actions' : undefined}
        className="inline-flex items-center p-0.5 focus:outline-none rounded-full focus:ring-2 focus:ring-offset-2 focus:ring-team"
        aria-label="Group options"
        onClick={() => setIsOpen(!isOpen)}>
        <EllipsisVerticalIcon className="w-4 h-4" />
      </button>
    </Popover>
  )
}

export const CreateGroupModal = ({ close, activeWorkspaceId, fromHomeGroups }: {close: () => void, activeWorkspaceId: any, refetch?: () => void, fromHomeGroups?: boolean}) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const email = appData?.appState?.loggedInAs.email

  const [distributionChanges, setDistributionChanges] = useState<Map<string, { addTo: string[], removeFrom: string[] }>>(new Map())
  const [companyName, setCompanyName] = useState<string | null>(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isChecked, setIsChecked] = useState(true)

  const { data: orgData, loading: orgLoading } = useQuery<GetUserOrganizationCompanies2Res, GetUserOrganizationCompanies2Vars>(getUserOrganizationCompanies2Query, {
    variables: {
      userId: appData?.appState?.loggedInAs.uid || '',
      organizationId: activeWorkspaceId,
    }
  })

  const { data, loading, refetch: refetchCompany } = useQuery<GetCompaniesAllowedToDistributeCourseToRes, GetCompaniesAllowedToDistributeCourseToVars>(
    getCompaniesAllowedToDistributeCourseToQuery,
    {
      variables: {
        userId: appData?.appState?.loggedInAs.uid || '',
        courseId,
        searchText: '',
        skip: 0,
        limit: 100,
        organizationId: orgData?.organization.companies[0]?.company.companyType === 'test' ? null : activeWorkspaceId,
      },
      nextFetchPolicy: 'network-only',
    },
    )

  const { data: pubData, refetch: refetchPubData2 } = useQuery<OrganizationAllowPublishToRes, { courseId: string, userId: string }>(getOrganizationsOrCmpsAllowedToPublishToQuery, {
    variables: { courseId, userId: appData?.appState?.loggedInAs.uid || '' },
    nextFetchPolicy: 'network-only',
  })

  const { data: distribData, refetch: distribRefetch2 } = useQuery<GetCompanyIdsCourseAlreadyDistributedToRes, GetCompanyIdsCourseAlreadyDistributedToVars>(
    getCompanyIdsCourseAlreadyDistributedToQuery,
    { variables: { courseId }, nextFetchPolicy: 'network-only' },
  )

  const { data: courseData, loading: courseLoading } = useQuery<GetUserAndActiveCourseResponse, GetUserAndActiveCourseVariables>(getUserAndActiveCourseQuery, {
    skip: !appData || !appData?.appState?.loggedInAs.uid || !appData.appState.currentCompanyIdCourseId,
    variables: {
      companyId,
      courseId,
      userId: appData?.appState?.loggedInAs.uid || '',
    },
    errorPolicy: 'all',
  })

  const [createCompany] = useMutation(CREATE_COMPANY)

  const [toggleDistribution2] = useMutation<ToggleOrgsCourseDistributionRes, ToggleOrgsCourseDistributionVars>(toggleOrgsCourseDistributionMutation, {
    onCompleted: (res) => {
      track({
        event: 'Program Distributed',
        variables: {
          course_id: courseId,
          user_id: appData?.appState?.loggedInAs.uid || '',
        },
      })

      // toast.success('Much success!')
      setDistributionChanges(new Map())
      // refetchAllData(5000)
    },
    onError: (err) => {
      toast.error(`Error - ${err.message}`)
      console.error(err)
    },
  })



  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const create = () => {
    if (!companyName) {
      return alert('Group name is mandatory')
    }
    setIsCreating(true)
    createCompany({
      variables: {
        organizationId: activeWorkspaceId,
        companyCreate: {
          name: companyName,
        },
      },
      update: () => {
        setTimeout(() => {
          // toast.success('Much success!')
          track({
            event: 'Company Created',
            variables: {
              organization_id: activeWorkspaceId,
              user_id: appData?.appState?.loggedInAs.uid || '',
            },
          })
          refetchPubData2()
          refetchCompany()
          close()
        }, 1000)
      },
      onError: (err) => {
        toast.error(`Error - ${err.message}`)
        close()
      },
      onCompleted: (data) => {
        const newCompanyId = data.company.id
        if (isChecked) {
          setTimeout(() => {
            const res = inviteByEmail(newCompanyId, email || '')
          }, 1000)
        }
        setTimeout(() => {
          toggleDistribution2({
            variables: {
              courseId,
              organizations: [{
                organizationId: data.company.organizationId,
                addTo: { onlyCompanyIds: [newCompanyId] },
                removeFrom: { onlyCompanyIds: [] },
              }],
              distributionParams: {
                courseId,
              },
              onlyVisibleInAnalyticsInMetrics: false,
            },
          })
        }, 1000)

          const modal = showModal({
            title: intl.get('program_menu_invite_learners'),
            component: fromHomeGroups ? <Invite companyId={newCompanyId} home={true} groupName={data.company.name} isAgent={true} orgId={data.company.organizationId} close={() => modal.close()} fromHomeGroups={true} /> : <Invite companyId={newCompanyId} home={true} groupName={data.company.name} isAgent={true} createGroup={true} close={() => modal.close()} courseName={courseData?.course.title} courseId={courseId} orgId={data.company.organizationId} />,
            secondaryButton: false,
            primaryText: intl.get('global_close'),
            hideFooter: true,
            className: 'add-action-modal',
          })

        setTimeout(() => {
          distribRefetch2()
        }, 3000)
      },
    })
  }



  return (
    <div>
      <section className="mt-4 mb-6">
        <p className="pb-2">{intl.get('share_with_group_create_group_dialog_enter')}</p>
        <input type="text" placeholder="My Group" className="form-input w-full" onChange={e => setCompanyName(e.target.value)} />
      </section>
      <section className="flex mb-6">
        <input type="checkbox" id="addMyself" className="h-4 w-4 m-auto rounded border-gray-300 text-lake focus:ring-lake cursor-pointer" checked={isChecked} onChange={handleCheckboxChange} />
        <label htmlFor="addMyself" className="m-auto pb-0 text-base pl-4 cursor-pointer">{intl.get('add_myself_as_learner_to_group')}</label>
      </section>

      <section className="flex">
        <div className="w-1/2 m-auto justify-start">
          <div className="inline-block px-3 py-1 m-auto bg-[#E3E4E5] rounded-2xl text-xs">
            {intl.get('steps_dynamic', { 0: 1, 1: 2 })}
          </div>
        </div>
        <div className="justify-end mt-4 flex gap-4 w-1/2">
          <Button id="cancel-new-group-button" text={intl.get('cancel')} onClick={close} />
          <Button id="create-new-group-button" type="team" text={intl.get('create_and_continue')} disabled={isCreating} onClick={create} />
        </div>
      </section>
    </div>
  )
}
