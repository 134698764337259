import React from 'react'
import intl from 'react-intl-universal'
import { GetCurrentSubscriptionRes } from 'graphql/queries/user/getUser'
import { Company } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { PeerInvite } from 'components/navbar/course-list/Item'
import { showModal } from 'components/utils/CustomModal'
import { CourseItem } from './CourseItem'
import { PinCompany } from './CompanyPin'
import { isAgent } from 'graphql/schemas/user/User'
import { stringSlicer } from 'utils/functions'

type CompanyBoxProps = {
  isActive: boolean;
  subData: GetCurrentSubscriptionRes;
  company: Company;
  courses: (Course&UserProgress)[]|undefined;
  expanded: string|null;
  pinnable: boolean;
  archive?: boolean;
  onExpand: (org: string|null) => void;
}

export const CompanyBox = ({ isActive, subData, company, courses, expanded, pinnable, archive, onExpand }: CompanyBoxProps) => {
  const companyKey = `${company.organizationId}-${company.id}`
  const groupName = company.groupName || company.name
  const organizationName = company.organizationName
  const isExpanded = expanded === companyKey
  const canInvite = !archive && (company.peerInvitationMode === 'approval' || company.peerInvitationMode === 'open' || isAgent(subData.user, company.organizationId, company.id))

  return (
    <div id={`${company.organizationId}-${company.id}`}>
      <div className="px-6 py-4 bg-white shadow-sm flex flex-col md:flex-row justify-between rounded-md">
        <div className="relative">
          <div className="font-bold text-lg">{stringSlicer(groupName, 65)}</div>
          <div className="text-sm">{organizationName}</div>

          {!archive && (
            <div className="absolute right-0 top-0 md:hidden">
              <a href="#" tabIndex={-1} className={`icon icon-angle-right-light w-5 text-deepgray hover:text-coral cursor-pointer ${isExpanded ? 'up' : 'down'} text-3xl`} onClick={(e) => {
                e.preventDefault()
                onExpand(isExpanded ? null : companyKey)
              }} />

              {pinnable && (
                <div>
                  <PinCompany
                    userId={subData.user.id}
                    companyId={company.id}
                    pinnedCompanyIds={subData.user.pinnedCompanyIds}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row md:items-center mt-4 md:mt-0 space-y-4 md:space-y-0 md:space-x-10">
          <div>
            <div className="flex">
              <span className="inline-block mr-3 text-lake">
                <i className="icon icon-users-solid w-8 md:w-auto" /> +
              </span>

              <div>
                <div className="font-bold">
                  <button className={canInvite ? 'text-deepgray hover:text-coral' : 'text-gray-400 cursor-not-allowed'} onClick={() => {
                    if (!canInvite) return

                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: (
                        <PeerInvite
                          companyId={company.id}
                          isAgent={isAgent}
                          onClose={() => modal.close()}
                        />
                      ),
                      secondaryButton: false,
                      primaryText: intl.get('global_close'),
                    })
                  }}>
                    {intl.get('program_menu_invite_participants')}
                  </button>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>

          {!archive && (
            <>
              {pinnable && (
                <div className="hidden md:block">
                  <PinCompany
                    userId={subData.user.id}
                    companyId={company.id}
                    pinnedCompanyIds={subData.user.pinnedCompanyIds}
                  />
                </div>
              )}

              <div className="hidden md:block">
                <button
                  className={`icon icon-angle-right-light text-deepgray hover:text-coral cursor-pointer ${isExpanded ? 'up' : 'down'} text-3xl`}
                  onClick={() => onExpand(isExpanded ? null : companyKey)}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {courses && isExpanded && (
        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 4xl:grid-cols-8 gap-4">
          {courses.map((course) => {
            if (!course) return null
            const subKey = `${company.id}-${course.id}`
            const isLiked = subData.user.favoritedSubscriptions.includes(subKey)
            const progress = (course as any).progress
            return (
              <CourseItem
                key={subKey}
                userId={subData.user.id}
                company={company}
                course={course}
                liked={isLiked}
                isArchived={!!archive}
                isActive={isActive}
                isAgent={isAgent(subData.user, company.organizationId, company.id)}
                isEditor={subData.user.editorCourses.includes(course.id)}
                paddingRight={false}
                dontShowGroups={true}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
