import React from 'react'
import intl from 'react-intl-universal'

export const ChapterOrder = ({ order, courseTitle }: { order: number, courseTitle: string }) => {
  return (
    <div className="session-number mt-4 md:mt-0 text-teak">
      <div className="scroller">
        <div className="course-title">{intl.get('session_number', { 0: order })}</div>
        <div>{courseTitle}</div>
      </div>
    </div>
  )
}
