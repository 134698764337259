import Tippy from '@tippyjs/react'
import { MoreButton } from 'components/home/MoreButton'
import { ChaptersUnlocked } from 'graphql/schemas/course/Course'
import React from 'react'
import intl from 'react-intl-universal'
import { useLocation, useNavigate } from 'react-router-dom'
import { track } from 'utils/track'

type MiniCourseDetailsProps = {
  isEditing: boolean;
  companyId: string;
  companyName: string;
  courseId: string;
  courseTitle: string;
  completion: number;
  canInvite: boolean;
  isEditor: boolean;
  isAgent: boolean;
  isSandbox: boolean;
  courseType: ChaptersUnlocked|undefined;
  rtlDir?: React.CSSProperties | {} | undefined;
}

export const MiniCourseDetails = ({ isEditing, companyId, companyName, courseId, courseTitle, completion, canInvite, isEditor, isAgent, isSandbox, courseType, rtlDir }: MiniCourseDetailsProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  let courseTypeName = courseType === 'unlocked'
    ? intl.get('course_unlocked_mode')
    : courseType === 'daily'
      ? intl.get('course_daily_mode')
      : intl.get('course_streak_mode')

  return (
    <>
      <div className="text-sm font-medium flex">
        {isEditing
          ? <div>{intl.get('program')} (<Tippy placement="right" content="Click here to change learning mode."><button onClick={() => navigate('/home/publish')}>{courseTypeName}</button></Tippy>)</div>
          : <>
              <div className="text-actions-multiple_choice flex-1 truncate mr-4">{companyName}</div>
              <div className="relative mt-0.5">
                <MoreButton
                  companyId={companyId}
                  courseId={courseId}
                  courseTitle={courseTitle || 'Program'}
                  canInvite={canInvite}
                  isEditor={isEditor}
                  isAgent={isAgent}
                  isSandbox={isSandbox}
                  groupName={companyName}
                />
              </div>
            </>
        }
      </div>

      <div className="text-lg text-deepgray mt-1 flex">
        <button
          style={rtlDir}
          className={`flex-1 ${(rtlDir && Object.keys(rtlDir).length === 0) && 'text-left'} font-bold hover:text-actions-multiple_choice ${location.pathname.includes('/details') ? 'underline' : ''}`}
          onClick={() => {
            track({
              event: 'Program Title Clicked',
              variables: {
                onScreen: 'Learn',
              }
            })
            navigate(`/details/${courseId}`)
          }}>
          {courseTitle}
        </button>
      </div>

      {!isEditing && (
        <div className="flex items-center mt-3">
          <div className="bg-deadyellow rounded-md relative h-2 flex-1">
            <span className="inline-block bg-lake h-2 rounded-md absolute transition-all" style={{ width: `${completion}%` }} />
          </div>

          <div className="ml-3 text-sm">
            {completion}%
          </div>
        </div>
      )}
    </>
  )
}
