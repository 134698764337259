import React, { useLayoutEffect } from 'react'
import intl from 'react-intl-universal'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { Post } from 'graphql/schemas/group/Post'
import { User } from 'graphql/schemas/user/User'
import { camelCase } from 'utils/functions'
import { ShareDetails } from './ShareDetails'
import { showModal } from 'components/utils/CustomModal'
import WallItem from './Item'
import { track } from 'utils/track'
import { BrowserRouter, useNavigate } from 'react-router-dom'

// https://medium.com/@andybarefoot/a-masonry-style-layout-using-css-grid-8c663d355ebb

type SharesGridProps = {
  uid: string;
  isAgent: boolean;
  posts: any;
  users: any;
  chapters: Chapter[];
  otherShares?: boolean;
  isNew?: boolean;
}

export const SharesGrid = ({ uid, isAgent, posts, users, chapters, otherShares, isNew }: SharesGridProps) => {
  const navigate = useNavigate()

  let rowHeight
  let rowGap
  let grid

  useLayoutEffect(() => {
    resize()
  }, [posts, users])

  const resize = () => {
    grid = document.querySelector('.journey-grid')
    if (!grid) return
    grid.style.gridAutoRows = '1rem'
    rowHeight = getStyleValue(grid, 'grid-auto-rows')
    rowGap = getStyleValue(grid, 'grid-row-gap')
    grid.style.gridAutoRows = 'auto'
    grid.style.alignItems = 'self-start'
    resizeAllGridItems()
  }

  const resizeAllGridItems = () => {
    if (!grid) return
    grid.querySelectorAll('.journey-item-v2').forEach((item: any) => {
      item.style.gridRowEnd = `span ${Math.ceil(
        (item.querySelector('.journey-item-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap),
      )}`
      item.style.display = 'flex'
    })
    grid.removeAttribute('style')
  }

  const getStyleValue = (element, style) => {
    return parseInt(window.getComputedStyle(element).getPropertyValue(style))
  }

  const onShareSelected = (share: Post) =>  {
    if (!users || !chapters) return

    const str = [share.companyId, share.courseId, share.chapterId, share.actionId, share.userId].join('-')
    const shareUser = users.find(u => u.id === share.userId)
    const chapter = chapters.find(c => c.id === share.chapterId)
    const action = chapter?.actions.find(a => a.id === share.actionId)

    track({
      event: 'Group Share Opened',
      variables: {
        company_id: share.companyId,
        programId: share.courseId,
      },
    })

    if (otherShares) {
      return navigate(`/learn/share/${str}`)
    }

    const modal = showModal({
      component: (
        <BrowserRouter>
          <ShareDetails
            userId={uid}
            chapter={chapter}
            action={action}
            key={str}
            share={share}
            shareUser={shareUser}
            onClose={() => modal.close()}
          />
        </BrowserRouter>
      ),
      onlyContent: true,
      className: 'post',
    })
  }

  const sortedPosts: Post[] = posts?.posts && posts.posts.slice().sort((a, b) => b.createdAt - a.createdAt) || []
  const postUsers: Pick<User, 'id'|'profile'>[] = users

  if (sortedPosts.length <= 0) {
    return (
      otherShares
        ? <div className="my-6 ml-2">{camelCase(intl.get('journey_no_other_shares'))}</div>
        : <div className="my-6 ml-2">No Shares.</div>
    )
  }

  if (otherShares) {
    return (
      <div className="page wall shadow-md rounded-md px-8">
        <header className="relative mb-4">
          <div className="font-noto font-medium text-2xl text-medgray text-left">{camelCase(intl.get('journey_other_shares'))}</div>
        </header>

        <div className="journey-grid">
          {sortedPosts.map((post) => {
            const chapter = chapters.find(c => c.id === post.chapterId)
            const chapterOrder = chapter && chapter.order || null
            const user = postUsers.find(u => u.id === post.userId)

            return (
              <WallItem
                key={post.userId + post.actionId}
                share={post}
                user={user}
                userId={uid}
                isAgent={isAgent}
                shareChapterOrder={chapterOrder}
                isNew={isNew}
                onShareSelected={onShareSelected}
              />
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="journey-grid">
      {sortedPosts.map((post) => {
        const chapter = chapters.find(c => c.id === post.chapterId)
        const chapterOrder = chapter && chapter.order || null
        const user = postUsers.find(u => u.id === post.userId)

        return (
          <WallItem
            key={post.userId + post.actionId}
            share={post}
            user={user}
            userId={uid}
            isAgent={isAgent}
            shareChapterOrder={chapterOrder}
            isNew={isNew}
            onShareSelected={onShareSelected}
          />
        )
      })}
    </div>
  )
}
